import { Routes,Route } from "react-router-dom";
import Teams from "../pages/Teams";
import Home from '../pages/Home'
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import AddTeam from "../pages/Team";

const Routers = () =>{
    return(
        <>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/sign-up" element={<SignUp/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="/teams" element={<Teams/>}/>
                <Route path="/add-team" element={<AddTeam/>}/>
                <Route path="/edit-team/:id" element={<AddTeam/>}/>
            </Routes>
        </>
    );
}

export default Routers;