import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import SSPLOGO from "../../assets/images/SSP LOGO PNG (2).png";
import TEAM from "../../assets/images/SPL PNG.png";
import Detail from "../../assets/images/detail.png";
import NOTE from '../../assets/images/notes.png'

// Create Document Component
const TeamDetail = ({ detail, member, captain }) => {
  // Create styles
  const styles = StyleSheet.create({
    page: {
      fontSize: 10,
      padding: 15,
      textTransform: "uppercases",
    },
    headerFirst: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 20,
      paddingBottom: 12,
      border: "1px solid #acadac",
    },
    borderBox: {
      marginTop: 10,
      borderRadius: 20,
      padding: 20,
      border: "1px solid #acadac",
    },
    table: {
      width: "100%",
      borderTop: "1px solid #acadac",
      borderLeft: "1px solid #acadac",
      borderRight: "1px solid #acadac",
      borderBottom: "1px solid #acadac",
      marginTop: 10,
      borderRadius: 20,
      color: "#000",
      //==height:"40%"
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: 20,
      alignItems: "center",
      padding: "12px 10px 12px 5px",
      borderBottom: "1px solid #acadac",
      fontSize: 10,
      fontWeight: 600,
      marginBottom: 5,
    },
    tableBody: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 5,
      height: "auto",
      marginBottom: 10,
      borderBottom: 0.2,
    },
    tableHeaderFirstCell: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      //fontWeight: 600
    },
    tableHeaderOtherCell: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      //fontWeight: 600
    },
    tableFirstCell: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      //fontWeight: 600
    },
    tableOtherCell: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.headerFirst}>
            <View>
              <Image src={SSPLOGO} style={{ width: 300, height: 100 }} />
              <Image src={Detail} style={{ width: 350, height: 100 }} />
            </View>
            <Image src={TEAM} style={{ width: 160, height: 140 }} />
          </View>
          <View style={styles.borderBox}>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontWeight: "ultrabold",
                  marginRight: 10,
                  fontSize: 12,
                  color: "#000",
                }}
              >
                AGE CATEGORY :
              </Text>
              <Text
                style={{
                  fontWeight: "ultrabold",
                  marginRight: 10,
                  fontSize: 12,
                  color: "#000",
                }}
              >
                {detail?.tournament}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                  }}
                >
                  TEAM NAME :
                </Text>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                    textTransform: "uppercase",
                  }}
                >
                  {detail?.name}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                  }}
                >
                  AREA NAME :
                </Text>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                    textTransform: "uppercase",
                  }}
                >
                  {detail?.area}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                  }}
                >
                  CAPTAIN NAME :
                </Text>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                    textTransform: "uppercase",
                  }}
                >
                  {captain?.name}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                  }}
                >
                  MOBILE NO :
                </Text>
                <Text
                  style={{
                    fontWeight: "ultrabold",
                    marginRight: 10,
                    fontSize: 12,
                    color: "#000",
                  }}
                >
                  {captain?.phone}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <Text style={{ ...styles.tableHeaderOtherCell, width: "10%" }}>
                No.
              </Text>
              <Text
                style={{
                  ...styles.tableHeaderFirstCell,
                  width: "45%",
                  textAlign: "center",
                }}
              >
                PLAYER NAME
              </Text>
              <Text style={{ ...styles.tableHeaderOtherCell, width: "25%" }}>
                AGE
              </Text>
              <Text style={{ ...styles.tableHeaderOtherCell, width: "25%" }}>
                MOBILE NUMBER
              </Text>
            </View>
            {member &&
              member.length &&
              member.map((el, index) => {
                return (
                  <View style={styles.tableBody}>
                    <Text style={{ ...styles.tableFirstCell, width: "10%" }}>
                      {index + 1 || "-"}
                    </Text>
                    <Text
                      style={{
                        ...styles.tableOtherCell,
                        width: "45%",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      {el?.name || "-"}
                    </Text>
                    <Text style={{ ...styles.tableOtherCell, width: "25%" }}>
                      {el?.age || "-"}
                    </Text>
                    <Text style={{ ...styles.tableOtherCell, width: "25%" }}>
                      {el?.phone || "-"}
                    </Text>
                  </View>
                );
              })}
          </View>
          <Image src={NOTE} style={{ width: "100%", height: 120, marginTop: 5 }} />
        </View>
      </Page>
    </Document>
  );
};

export default TeamDetail;
