import React, { Fragment, useEffect, useState } from "react";
import DefaultLogo from "../../assets/images/default_logo.png";
import Select from "react-select";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import SPLLOGO from "../../assets/images/SPL PNG.png";

const AddTeam = () => {
  const inputFileRef = React.createRef();
  const [logoImg, setLogoImg] = useState();
  const [teamDetail, setTeamDetail] = useState({});
  const [openDetailPage, setOpenDetailPage] = useState(false);
  const [error, setErrors] = useState({});
  const [perror, setPErrors] = useState([]);
  const [paymentBox, setPaymentBox] = useState(false);
  const [paymentModal, setPaymentModal] = useState(true);

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : "";

  const validateTeam = () => {
    let isError = false;
    let errors = {};

    const { name, area, tournament } = teamDetail;

    if (!name) {
      errors = { ...errors, name: "please enter team name" };
      isError = true;
      window.scrollTo(0, 0);
    } else {
      errors = { ...errors, name: "" };
    }

    if (!tournament) {
      errors = { ...errors, tournament: "please select category" };
      isError = true;
      window.scrollTo(0, 0);
    } else {
      errors = { ...errors, tournament: "" };
    }

    if (!area) {
      errors = { ...errors, area: "please enter area" };
      isError = true;
      window.scrollTo(0, 0);
    } else {
      errors = { ...errors, area: "" };
    }

    setErrors(errors);
    return isError;
  };

  const toggleModal = () => {
    setOpenDetailPage(!openDetailPage);
  };

  const gender =
    teamDetail?.tournament === "above_16_man" ||
    teamDetail?.tournament === "under_16_man"
      ? "Male"
      : "Female";

  const isPayment = teamDetail?.tournament === "above_16_man";

  const AgeOptionUneder = [
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
  ];

  const AgeOption = [
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
    { label: 51, value: 51 },
    { label: 52, value: 52 },
    { label: 53, value: 53 },
    { label: 54, value: 54 },
    { label: 55, value: 55 },
    { label: 56, value: 56 },
    { label: 57, value: 57 },
    { label: 58, value: 58 },
    { label: 59, value: 59 },
    { label: 60, value: 60 },
  ];

  const options =
    teamDetail?.tournament === "under_16_woman" ||
    teamDetail?.tournament === "under_16_man"
      ? AgeOptionUneder
      : AgeOption;

  const [playerDetail, setPlayerDetail] = useState([
    {
      name: "",
      age: "",
      phone: null,
      is_official_captian: false,
      gender: null,
      document: null,
      is_caption: false,
    },
  ]);
  const { id } = useParams();
  const navigate = useNavigate();

  const Tournaments = [
    { label: "BOYES 10 TO 15 YEAR", value: "under_16_man" },
    { label: "BOYES 16 TO OPEN", value: "above_16_man" },
    { label: "GIRLS 10 TO 15 YEAR", value: "under_16_woman" },
    { label: "GIRLS 16 TO OPEN", value: "above_16_woman" },
  ];

  const playerObj = {
    name: "",
    age: "",
    phone: null,
    is_official_captian: false,
    gender: null,
    document: null,
    is_caption: false,
  };

  const handleBtnClick = () => {
    inputFileRef.current.click();
  };

  const checkPlayerDetail = async (data) => {
    try {
      const response = await axios.post("/user/check-user", data);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const validate = () => {
    let isError = false;
    let errors = [];

    playerDetail.forEach((player, index) => {
      const { name, age, phone, document } = player;

      if (!name) {
        errors[index] = { ...errors[index], name: "please enter name" };
        isError = true;
      } else {
        errors[index] = { ...errors[index], name: "" };
      }

      if (!age) {
        errors[index] = { ...errors[index], age: "please select age" };
        isError = true;
      } else {
        errors[index] = { ...errors[index], age: "" };
      }

      if (!phone) {
        errors[index] = {
          ...errors[index],
          phone: "please enter mobile number",
        };
        isError = true;
      } else if (phone.toString().length !== 10) {
        errors[index] = {
          ...errors[index],
          phone: "please enter valid mobile number",
        };
        isError = true;
      } else if (
        playerDetail.filter((f, findex) => f.phone == phone && findex !== index)
          .length > 0
      ) {
        const PlayerIndex = playerDetail.findIndex(
          (f, findex) => f.phone == phone && findex !== index
        );
        errors[PlayerIndex] = {
          ...errors[PlayerIndex],
          phone: "this mobile number is already in use",
        };
        isError = true;
      } else {
        errors[index] = { ...errors[index], phone: "" };
      }

      if (!document) {
        errors[index] = { ...errors[index], document: "please select Aadhar" };
        isError = true;
      } else {
        errors[index] = { ...errors[index], document: "" };
      }
    });

    setPErrors(errors);
    return isError;
  };

  const AddPlayer = async () => {
    if (!validate()) {
      if (!id) {
        const LatestPlayer = playerDetail[playerDetail.length - 1];
        const data = { name: LatestPlayer.name, phone: LatestPlayer.phone };
        if (await checkPlayerDetail(data)) {
          setPlayerDetail([...playerDetail, playerObj]);
        }
      } else {
        setPlayerDetail([...playerDetail, playerObj]);
      }
    }
  };

  const DeletePlayer = (index) => {
    playerDetail.splice(index, 1);
    setPlayerDetail([...playerDetail]);
  };

  const DeleteUser = async (id) => {
    try {
      const response = await axios.delete(`/user/${id}`);
      toast.success("user deleted succussfully !!");
      getTeamDetail();
    } catch (error) {}
  };

  const handleChange = (name, value) => {
    setTeamDetail({ ...teamDetail, [name]: value });
  };

  const handlePlayerChange = (name, value, index) => {
    playerDetail[index] = {
      ...playerDetail[index],
      [name]: value,
    };

    setPlayerDetail([...playerDetail]);
  };

  const addTeam = async () => {
    if (playerDetail?.length >= 10) {
      if (!validateTeam()) {
        if (!validate()) {
          try {
            let method = id ? "put" : "post";
            let formData = new FormData();
            for (const property in teamDetail) {
              formData.append(property, teamDetail[property]);
            }
            if (logoImg) {
              formData.append("logo", logoImg);
            }
            const response = await axios[method]("/team", formData);
            if (response.data) {
              createPlayer(response.data?.payload?._id);
            }
          } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
          }
        }
      }
    } else {
      alert("please enter minimum 11 players");
    }
  };

  const addPlayerData = async (player, index, isPlayer) => {
    try {
      if (player) {
        let method = isPlayer ? "put" : "post";
        let route = isPlayer ? `/user/${player._id}` : "/user/register-player";
        let formData = new FormData();
        for (const property in player) {
          formData.append(property, player[property]);
        }

        await axios[method](route, formData);
      }

      if (playerDetail?.length - 1 === index) {
        if (id) {
          toast.success("player updated succesfully !!");
          navigate("/teams");
        }
      }

      if (playerDetail?.length - 1 === index && !id && isPayment) {
        displayRazorpay();
      }

      if (playerDetail?.length - 1 === index && !id && !isPayment) {
        toast.success("player added succesfully !!");
        navigate("/teams");
      }
      
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
  };

  const createPlayer = async (team_id) => {
    try {
      if (playerDetail.length > 0) {
        playerDetail.forEach((player, index) => {
          player.team = team_id;
          player.gender = gender;
          player.password = `player${player?.phone}`;
          addPlayerData(player, index, player?._id);
        });
      }
    } catch (error) {}
  };

  const getTeamDetail = async () => {
    try {
      const response = await axios.get(`/team/${id}`);
      const { details, member } = response.data?.payload;
      setTeamDetail(details);
      setPlayerDetail(member);
    } catch (error) {
      console.log(error);
    }
  };

  const paymentCancel = async () => {
    try {
      await axios.get("team/payment-cancel");
    } catch (error) {
      console.log(error);
    }
  };

  //rozarpay
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    setPaymentBox(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // creating a new order
    const result = await axios.post("team/add-payment");

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data.payload;

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "SPL",
      description: "Test Transaction",
      image: { SPLLOGO },
      order_id: order_id,
      handler: async function (response) {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        try {
          setPaymentBox(false);
          const response = await axios.post("/team/payment", data);
          window.location.href = response.data?.redirectURL;
        } catch (error) {
          setPaymentBox(false);
          console.log(error);
        }
      },
      modal: {
        ondismiss: function () {
          setPaymentBox(false);
          // Handle popup closure here (optional)
          paymentCancel();
        },
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // const paymentSucess = async() => {
  //   try{
  //     await axios.post("/team/payment")
  //   }catch(error){

  //   }
  // }

  // useEffect(() => {
  //   const rzpPaymentForm = document.getElementById("rzp_payment_form");

  //   if (!rzpPaymentForm.hasChildNodes()) {
  //     const script = document.createElement("script");
  //     script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //     script.async = true;
  //     script.dataset.payment_button_id = process.env.REACT_APP_BUTTON_KEY;
  //     rzpPaymentForm.appendChild(script);
  //   }

  //    // Handling payment success
  //    window.addEventListener('payment.success', function(event) {
  //     addTeam()
  //   });

  //    // Handling payment failure
  //    window.addEventListener('payment.error', function(event) {
  //     console.log('Payment failed!', event.detail);
  //     // You can perform actions here like displaying an error message to the user
  //     // or log the failure for further analysis
  //   });

  //   // Handling webhook events
  //   window.addEventListener('razorpay.webhook', function(event) {
  //     paymentSucess()
  //   });
  // }, []);

  useEffect(() => {
    if (id) {
      getTeamDetail();
    }
  }, [id]);

  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card p-5 shadow rounded-lg login-form">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-12">
                  <form>
                    <h3 className="mb-5">Add Team Detail</h3>
                    <div className="add_team_logo">
                      <img
                        src={
                          logoImg
                            ? URL.createObjectURL(logoImg)
                            : teamDetail?.logo
                            ? `${process.env.REACT_APP_BASEURL}/image/${teamDetail?.logo}`
                            : DefaultLogo
                        }
                        style={{ cursor: "pointer" }}
                        alt="logo"
                        onClick={() => handleBtnClick()}
                      />
                    </div>
                    <p className="mt-1 mb-2 ml-4">Team Logo</p>
                    <input
                      type="file"
                      ref={inputFileRef}
                      style={{ display: "none" }}
                      name="logo"
                      onChange={(e) => setLogoImg(e.target.files[0])}
                      accept="image/*"
                    />
                    <div className="form-group mt-3">
                      <label>
                        Team Name <span className="error-text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Team Name"
                        value={teamDetail?.name || ""}
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      {error?.name && (
                        <span className="error-text">{error?.name}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Select Category <span className="error-text">*</span>
                      </label>
                      <Select
                        options={Tournaments}
                        isDisabled={id ? true : false}
                        value={
                          Tournaments.find(
                            (f) => f.value === teamDetail?.tournament
                          ) || null
                        }
                        onChange={(e) => handleChange("tournament", e.value)}
                      />
                      {error?.tournament && (
                        <span className="error-text">{error?.tournament}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Area <span className="error-text">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Area Name"
                        value={teamDetail?.area || ""}
                        onChange={(e) => handleChange("area", e.target.value)}
                      />
                      {error?.area && (
                        <span className="error-text">{error?.area}</span>
                      )}
                    </div>
                    <hr />
                    {!id && (
                      <Fragment>
                        <div className="d-flex justify-content-between">
                          <h6 className="mb-3 text-primary">Player Detail 1</h6>
                        </div>
                        <div className="form-group">
                          <label>
                            Player Name <span className="error-text">*</span>
                          </label>
                          <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Player Name"
                                value={userData?.name || ""}
                                disabled
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                              <div className="form-group form-check">
                                <div>
                                  <input
                                    type="checkbox"
                                    className="form-check-input largerCheckbox"
                                    id={`player-detail`}
                                    checked={userData?.is_captain || false}
                                    disabled
                                  />
                                  <label
                                    className="form-check-label"
                                    for={`player-detail`}
                                  >
                                    CAPTAIN
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 col-md-5 col-12">
                            <div className="form-group">
                              <label>
                                Age <span className="error-text">*</span>
                              </label>
                              <Select
                                options={options}
                                isDisabled
                                value={
                                  options.find(
                                    (f) => f.value === userData?.age
                                  ) || null
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-7 col-12">
                            <div className="form-group">
                              <label>
                                Phone <span className="error-text">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                disabled
                                placeholder="Enter Mobile No."
                                value={userData?.phone || ""}
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    )}
                    {playerDetail.length > 0 &&
                      playerDetail.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <div className="d-flex justify-content-between">
                              <h6 className="mb-3 text-primary">
                                Player Detail {index + (id ? 1 : 2)}
                              </h6>
                              {id ? (
                                <button
                                  className="btn-danger btn btn-sm"
                                  type="button"
                                  onClick={() => DeleteUser(item?._id)}
                                >
                                  Delete
                                </button>
                              ) : (
                                <button
                                  className="btn-danger btn btn-sm"
                                  type="button"
                                  onClick={() => DeletePlayer(index)}
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                            <div className="form-group">
                              <label>
                                Player Name{" "}
                                <span className="error-text">*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-8 col-md-8 col-12">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Player Name"
                                    value={playerDetail[index]?.name || ""}
                                    onChange={(e) =>
                                      handlePlayerChange(
                                        "name",
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  {perror[index]?.name && (
                                    <span className="error-text">
                                      {perror[index]?.name}
                                    </span>
                                  )}
                                </div>
                                <div className="col-lg-4 col-md-4 col-12">
                                  <div className="form-group form-check">
                                    <div>
                                      <input
                                        type="checkbox"
                                        className="form-check-input largerCheckbox"
                                        id={`exampleCheck${index}`}
                                        checked={
                                          playerDetail[index]
                                            ?.is_official_captian || false
                                        }
                                        onChange={(e) =>
                                          handlePlayerChange(
                                            "is_official_captian",
                                            e.target.checked,
                                            index
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        for={`exampleCheck${index}`}
                                      >
                                        CAPTAIN
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-5 col-md-5 col-12">
                                <div className="form-group">
                                  <label>
                                    Age <span className="error-text">*</span>
                                  </label>
                                  <Select
                                    options={options}
                                    value={
                                      options.find(
                                        (f) =>
                                          f.value === playerDetail[index]?.age
                                      ) || null
                                    }
                                    onChange={(e) =>
                                      handlePlayerChange("age", e.value, index)
                                    }
                                  />
                                  {perror[index]?.age && (
                                    <span className="error-text">
                                      {perror[index]?.age}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-7 col-md-7 col-12">
                                <div className="form-group">
                                  <label>
                                    Phone <span className="error-text">*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Mobile No."
                                    value={playerDetail[index]?.phone || ""}
                                    onChange={(e) =>
                                      handlePlayerChange(
                                        "phone",
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                  {perror[index]?.phone && (
                                    <span className="error-text">
                                      {perror[index]?.phone}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-12">
                                <div className="form-group">
                                  <label>
                                    Adhar Image{" "}
                                    <span className="error-text">*</span>
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder="Enter Mobile No."
                                    accept="image/*"
                                    onChange={(e) =>
                                      handlePlayerChange(
                                        "document",
                                        e.target.files[0],
                                        index
                                      )
                                    }
                                  />
                                  {perror[index]?.document && (
                                    <span className="error-text">
                                      {perror[index]?.document}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <hr />
                          </Fragment>
                        );
                      })}
                    {playerDetail?.length <= 13 && (
                      <div className="text-right">
                        <button
                          className="btn btn-primary btn-sm"
                          type="button"
                          onClick={() => AddPlayer()}
                        >
                          Add Player
                        </button>
                      </div>
                    )}
                    {id && (
                      <button
                        type="button"
                        className="btn btn-primary btn-block mt-5"
                        onClick={() => addTeam()}
                      >
                        {id ? "Edit" : "Add"} Team
                      </button>
                    )}
                    {!id && (
                      <button
                        type="button"
                        className="btn btn-primary btn-block mt-5"
                        onClick={() =>
                          playerDetail?.length >= 10
                            ? !validateTeam() && !validate() && toggleModal()
                            : alert("please add minimum 11 players")
                        }
                      >
                        {id ? "Edit" : "Add"} Team
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal"
        style={{ display: openDetailPage ? "block" : "none", overflow: "auto" }}
      >
        <div
          class="modal-dialog modal-dialog-centered modal-team-detail"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Team Detail
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpenDetailPage(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <p>
                    <b>Team Name:</b>&nbsp;{teamDetail?.name}
                  </p>
                  <p>
                    <b>Tournament:</b>&nbsp;
                    {
                      Tournaments.find(
                        (f) => f.value === teamDetail?.tournament
                      )?.label
                    }
                  </p>
                  <p>
                    <b>Area:</b>&nbsp;{teamDetail?.area}
                  </p>
                  <hr />
                  <p>
                    <b>Captain Name:</b>&nbsp;
                    {playerDetail.find((f) => f.is_official_captian)?.name ||
                      userData?.name}
                  </p>
                  <p>
                    <b>Captain Age:</b>&nbsp;
                    {playerDetail.find((f) => f.is_official_captian)?.age ||
                      userData?.age}
                  </p>
                  <p>
                    <b>Captain Mobile:</b>&nbsp;
                    {playerDetail.find((f) => f.is_official_captian)?.phone ||
                      userData?.phone}
                  </p>
                  <hr />
                </div>
                <div className="col-lg-8 col-md-8 col-12">
                  <div style={{ overflowX: "auto" }}>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Age</th>
                          <th scope="col">Mobile No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData && (
                          <tr>
                            <th scope="row">1</th>
                            <td>{userData.name}</td>
                            <td>{userData.age}</td>
                            <td>{userData.phone}</td>
                          </tr>
                        )}
                        {playerDetail.map((player, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 2}</th>
                            <td>{player.name}</td>
                            <td>{player.age}</td>
                            <td>{player.phone}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-block"
                data-target="#exampleModalCenter"
                onClick={() => addTeam()}
              >
                {isPayment ? "Pay Now" : "Add Team"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {paymentBox && (
        <div className="qr-scan text-center">
          <b>Don't Pay with QR</b>
        </div>
      )}

      {paymentModal && (
        <div class="modal" style={{ display: "block" }}>
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Note
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setPaymentModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3>QR કોડ થી કોઈએ પેમેન્ટ કરવું નહીં. </h3>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => setPaymentModal(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddTeam;
