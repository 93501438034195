import { NavLink, useNavigate } from "react-router-dom";
import LoginImg from "../../assets/images/LoginIMG.png";
import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Select from "react-select";

const SignUp = () => {
  const [signUpDetail, setSignUpDetail] = useState({});
  const [error, setErrors] = useState({});
  const navigate = useNavigate();

  const AgeOption = [
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
    { label: 18, value: 18 },
    { label: 19, value: 19 },
    { label: 20, value: 20 },
    { label: 21, value: 21 },
    { label: 22, value: 22 },
    { label: 23, value: 23 },
    { label: 24, value: 24 },
    { label: 25, value: 25 },
    { label: 26, value: 26 },
    { label: 27, value: 27 },
    { label: 28, value: 28 },
    { label: 29, value: 29 },
    { label: 30, value: 30 },
    { label: 31, value: 31 },
    { label: 32, value: 32 },
    { label: 33, value: 33 },
    { label: 34, value: 34 },
    { label: 35, value: 35 },
    { label: 36, value: 36 },
    { label: 37, value: 37 },
    { label: 38, value: 38 },
    { label: 39, value: 39 },
    { label: 40, value: 40 },
    { label: 41, value: 41 },
    { label: 42, value: 42 },
    { label: 43, value: 43 },
    { label: 44, value: 44 },
    { label: 45, value: 45 },
    { label: 46, value: 46 },
    { label: 47, value: 47 },
    { label: 48, value: 48 },
    { label: 49, value: 49 },
    { label: 50, value: 50 },
  ];

  const Gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  const handleChange = (name, value) => {
    setSignUpDetail({ ...signUpDetail, [name]: value });
  };

  const validate = () => {
    let isError = false;
    let errors = {};

    const { name, gender, age, phone, document, password } = signUpDetail;

    if (!name) {
      errors = { ...errors, name: "please enter name" };
      isError = true;
    } else {
      errors = { ...errors, name: "" };
    }

    if (!gender) {
      errors = { ...errors, gender: "please select gender" };
      isError = true;
    } else {
      errors = { ...errors, gender: "" };
    }

    if (!age) {
      errors = { ...errors, age: "please select age" };
      isError = true;
    } else {
      errors = { ...errors, age: "" };
    }

    if (!phone) {
      errors = { ...errors, phone: "please enter mobile number" };
      isError = true;
    } else if (phone.length !== 10){
      errors = { ...errors, phone: "please enter valid mobile number" };
      isError = true;
    } else {
      errors = { ...errors, phone: "" };
    }

    if (!document) {
      errors = { ...errors, document: "please select Aadhar" };
      isError = true;
    } else {
      errors = { ...errors, document: "" };
    }

    if (!password) {
      errors = { ...errors, password: "please enter password" };
      isError = true;
    } else {
      errors = { ...errors, password: "" };
    }

    setErrors(errors);
    return isError;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      try {
        let formData = new FormData();
        for (const property in signUpDetail) {
          formData.append(property, signUpDetail[property]);
        }
        formData.append("is_captain", true);
        const response = await axios.post("/user/register", formData);
        if (response?.data) {
          toast.success("user registration successfully !!!", {
            position: "top-right",
          });
          navigate("/");
        }
      } catch (error) {
        toast.error(error.response?.data?.message, {
          position: "top-right",
        });
        console.log(error);
      }
    }
  };

  return (
    <div className="center">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <img src={LoginImg} alt="img" width="80%" className="login_img"/>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="card py-3 px-5 shadow rounded-lg w-75 login-form">
              <form>
                <h3 className="mb-3">Sign Up</h3>
                <div className="form-group">
                  <label for="exampleInputEmail1">Full Name <span className="error-text">*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    onChange={(e) => {
                      handleChange("name", e.target.value);
                    }}
                  />
                  {error?.name && <span className="error-text">{error?.name}</span>}
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Gender <span className="error-text">*</span></label>
                      <Select
                        options={Gender}
                        value={
                          Gender.find(
                            (f) => f.value === signUpDetail?.gender
                          ) || null
                        }
                        onChange={(e) => handleChange("gender", e.value)}
                      />
                      {error?.gender && <span className="error-text">{error?.gender}</span>}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Age <span className="error-text">*</span></label>
                      <Select
                        options={AgeOption}
                        value={
                          AgeOption.find(
                            (f) => f.value === signUpDetail?.age
                          ) || null
                        }
                        onChange={(e) => handleChange("age", e.value)}
                      />
                      {error?.age && <span className="error-text">{error?.age}</span>}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Mobile <span className="error-text">*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile"
                    onChange={(e) => {
                      handleChange("phone", e.target.value);
                    }}
                  />
                  {error?.phone && <span className="error-text">{error?.phone}</span>}
                </div>
                <div className="form-group">
                  <label for="exampleInputEmail1">Adhar Image <span className="error-text">*</span></label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Select File"
                    onChange={(e) => {
                      handleChange("document", e.target.files[0]);
                    }}
                  />
                  {error?.document && <span className="error-text">{error?.document}</span>}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Password <span className="error-text">*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => {
                      handleChange("password", e.target.value);
                    }}
                  />
                  {error?.password && <span className="error-text">{error?.password}</span>}
                </div>
                <button
                  type="button"
                  className="btn
                 btn-primary btn-block"
                  onClick={() => handleSubmit()}
                >
                  Sign Up
                </button>
                <div className="mt-2">
                  <label>Already Member ?</label>
                  <NavLink to="/">
                    <label className="ml-2">Login</label>
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
