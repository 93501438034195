import { NavLink, useNavigate } from "react-router-dom";
import TeamLogo from "../../assets/images/6403767.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import TeamDetail from "./TeamPDF";
import toast from "react-hot-toast";

const Home = () => {
  const [teams, setTeams] = useState([]);
  const [ImageUrls, setImageUrls] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const Tournaments = [
    { label: "BOYES 10 TO 15 YEAR", value: "under_16_man" },
    { label: "BOYES 16 TO OPEN", value: "above_16_man" },
    { label: "GIRLS 10 TO 15 YEAR", value: "under_16_woman" },
    { label: "GIRLS 16 TO OPEN", value: "above_16_woman" },
  ];

  const getTeams = async () => {
    try {
      const response = await axios.get("/team");
      setTeams(response.data?.payload);

      const urls = await Promise.all(
        response.data?.payload.map(async (team) => {
          if (team?.logo) {
            try {
              const response = await axios.get(`/image/${team?.logo}`, {
                responseType: "arraybuffer",
              });
              const blob = new Blob([response.data], { type: "image/jpeg" });
              return URL.createObjectURL(blob);
            } catch (error) {
              console.error("Error fetching image:", error);
              return ""; // or provide a default image URL
            }
          } else {
            return "";
          }
        })
      );
      setImageUrls(urls);
    } catch (error) {
      console.log(error);
    }
  };

  const getImage = async (img) => {
    try {
      const response = await axios.get(`/image/${img}`, {
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], { type: "image/jpeg" }); // Convert array buffer to blob
      const dataUrl = URL.createObjectURL(blob);
      return dataUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamDetail = async (id) => {
    try {
      const response = await axios.get(`/team/${id}`);
      const { details, member } = response.data?.payload;
      details.tournament =
        Tournaments.find((f) => f.value === details?.tournament)?.label || "";
      let captain = member.find((f) => f.is_official_captian) || member[0];
      handlePDF(details, member, captain);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePDF = async (detail, member, captain) => {
    const blobData = await pdf(
      <TeamDetail detail={detail} member={member} captain={captain} />
    ).toBlob();
    const fileURL = URL.createObjectURL(blobData);
    window.open(fileURL);
  };

  const DeleteTeam = async () => {
    try {
      const response = await axios.delete(`/team/${deleteId}`);
      toast.success("Team Deleted Sucessfully !!");
      setOpenModal(false);
      getTeams();
    } catch (error) {
      setOpenModal(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);
  return (
    <div className="container mt-5">
      <div className="row">
        {teams && teams?.length > 0 ? (
          teams.map((team, index) => {
            return (
              <div className="col-lg-4 col-md-6 col-12" key={index}>
                <div class="card mb-4 shadow" style={{ borderRadius: 20 }}>
                  <div class="card-img-top">
                    <img
                      src={team?.logo ? `${process.env.REACT_APP_BASEURL}/image/${team?.logo}` : TeamLogo}
                      style={{
                        overflow: "hidden",
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                      }}
                      alt="Card"
                    />
                  </div>
                  <div class="card-body">
                    <table>
                      <tr>
                        <td>
                          <b>Team Name :</b>
                        </td>
                        <td>&nbsp;{team?.name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Tournament :</b>
                        </td>
                        <td>
                          &nbsp;
                          {Tournaments.find((f) => f.value === team?.tournament)
                            ?.label || ""}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Area :</b>
                        </td>
                        <td>&nbsp;{team?.area || ""}</td>
                      </tr>
                      {team?.is_matching &&
                        team?.tournament === "above_16_man" && (
                          <tr>
                            <td>
                              <b>Payment Status :</b>
                            </td>
                            <td>
                              &nbsp;
                              {team?.payment_status ? (
                                <span class="badge badge-success">
                                  Successfull
                                </span>
                              ) : (
                                <span class="badge badge-warning">Pending</span>
                              )}
                            </td>
                          </tr>
                        )}
                    </table>
                    {team?.is_matching && (
                      <>
                        <hr />
                        <div className="d-flex justify-content-center mt-4">
                          <button
                            className="btn btn-info"
                            onClick={() => getTeamDetail(team._id)}
                          >
                            Download PDF
                          </button>

                          <button
                            className="btn btn-primary ml-3"
                            onClick={() => navigate(`/edit-team/${team._id}`)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-danger ml-3"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            onClick={() => {
                              setDeleteId(team._id);
                              setOpenModal(true);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h1 className="text-center">No Team Found</h1>
        )}
      </div>

      {openModal && (
        <div class="modal" style={{ display: "block" }}>
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Delete
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpenModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Are you sure you want to delete team ?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-target="#exampleModalCenter"
                  onClick={() => DeleteTeam()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
