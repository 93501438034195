import Banner from "../../assets/images/tournament.jpg";

const Home = () => {
  return (
    <div>
      <img src={Banner} alt="img" width="100%"/>
      <div className="container mt-5">
        <div className="card mb-5 shadow p-4" style={{ borderRadius: 20 }}>
            <h2 className="main-title mb-4">ઓનલાઇન ટીમ રજીસ્ટર કરવા માટેની વિગતો</h2>
            <h5>👉 ટીમના કેપ્ટન એ સાઈન અપ કરી ફોર્મ ફિલ અપ કરવું</h5>
            <h5>👉 ટોટલ 14 પ્લેયર ના નામ એડ કરી શકશો</h5>
            <h5>👉 મિનિમમ 11 પ્લેયર એડ કરવા ફરજિયાત છે</h5>
            <h5>👉 જેના નામ અને નંબર થી સાઈન અપ કરેલું હશે તે ઓટોમેટીક ફર્સ્ટ પ્લેયર માં એડ થઈ જશે</h5>
            <h5>👉 જે ખેલાડી નો મોબાઇલ નંબર એક ટીમમાં રજીસ્ટર હશે તો તે ખેલાડીને બીજી કોઈ ટીમમાં એડ થઈ શકશે નહીં</h5>
            <h5>👉 રજીસ્ટ્રેશન ફિ ઓનલાઇન ભરવાની રહેશે જો ઓનલાઇન ફિ ભરેલી નહિ હોય તો તે ટીમ રજીસ્ટર થશે નહીં</h5>
        </div>
      </div>
    </div>
  );
};

export default Home;
