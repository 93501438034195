import { NavLink } from "react-router-dom";
import LoginImg from "../../assets/images/LoginIMG.png";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

const Login = () => {
  const [loginDetail, setLoginDetil] = useState({});
  const [isRemeber, setIsRemember] = useState({});
  const [error, setErrors] = useState({});

  const handleChange = (name, value) => {
    setLoginDetil({ ...loginDetail, [name]: value });
  };

  const validate = () => {
    let isError = false;
    let errors = {};

    const { phone, password } = loginDetail;

    if (!phone) {
      errors = { ...errors, phone: "please enter mobile number" };
      isError = true;
    } else if (phone.length !== 10) {
      errors = { ...errors, phone: "please enter valid mobile number" };
      isError = true;
    } else {
      errors = { ...errors, phone: "" };
    }

    if (!password) {
      errors = { ...errors, password: "please enter password" };
      isError = true;
    } else {
      errors = { ...errors, password: "" };
    }

    setErrors(errors);
    return isError;
  };

  const handleClick = async () => {
    if (!validate()) {
      try {
        const response = await axios.post("/user/login", loginDetail);
        if (response.data) {
          toast.success("login successfully !!!", { position: "top-right" });
          localStorage.setItem("jwtToken", response.data?.payload?.token);
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data?.payload)
          );
          if (isRemeber) {
            localStorage.setItem("remember", JSON.stringify(loginDetail));
          }
          window.location.href = "/home";
        }
      } catch (error) {
        toast.error(error.response?.data?.message, { position: "top-right" });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("remember")) {
      setLoginDetil(JSON.parse(localStorage.getItem("remember")));
      setIsRemember(true);
    }
  }, []);

  return (
    <div className="center">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-12">
            <img src={LoginImg} alt="img" width="80%" className="login_img"/>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="card p-5 shadow rounded-lg w-75 login-form">
              <form>
                <h3 className="mb-3">Login</h3>
                <div className="form-group">
                  <label for="exampleInputEmail1">Phone Number <span className="error-text">*</span></label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Number"
                    value={loginDetail?.phone}
                    onChange={(e) => handleChange("phone", e.target.value)}
                  />
                  {error?.phone && <span className="error-text">{error?.phone}</span>}
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Password <span className="error-text">*</span></label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={loginDetail?.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  />
                  {error?.password && <span className="error-text">{error?.password}</span>}
                </div>
                <div className="form-group form-check d-flex justify-content-between">
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      defaultChecked={isRemeber}
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      Remember Me
                    </label>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => handleClick()}
                >
                  Login
                </button>
                <div className="mt-2">
                  <label>New Member ?</label>
                  <NavLink to="/sign-up">
                    <label className="ml-2">Sign Up</label>
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
