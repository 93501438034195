import { useLocation } from "react-router-dom";
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import Routers from "./routes/router";

function App() {
  const location = useLocation()
  const showHF = ["/", "/sign-up"]
  const show = showHF.includes(location.pathname)
  return (
    <>
      {!show && <Header />}
      <Routers />
    </>
  );
}

export default App;
