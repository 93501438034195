import { NavLink } from "react-router-dom";
import Logo from "../assets/images/SPL PNG.png";
import { useEffect, useState } from "react";
import axios from "axios";

const Header = () => {
  const [showHeader, setShowHeader] = useState("");

  const handleLogOut = () => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("userData");
    window.location.href = "/";
  };

  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : "";

  const getUserDetail = async () => {
    try {
      const response = await axios.get(`/user/${userData?._id}`);
      setShowHeader(response?.data?.payload?.team);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark">
      <NavLink className="navbar-brand" to="/home">
        <img src={Logo} alt="logo" width={50} />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink className="nav-link" to="/home">
              Home <span className="sr-only">(current)</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/teams">
              Teams
            </NavLink>
          </li>
          {!showHeader && (
            <li className="nav-item">
              <NavLink className="nav-link" to="/add-team">
                Add New Team
              </NavLink>
            </li>
          )}
          <li
            className="nav-item"
            onClick={() => window.open(`${window.location.origin}/terms.jpeg`)}
          >
            <p className="nav-link p-0 mt-2">Terms & Condition</p>
          </li>
          <li
            className="nav-item ml-lg-4 ml-0"
            style={{ cursor: "pointer" }}
            onClick={() => handleLogOut()}
          >
            <p className="nav-link p-0 mt-2">Log Out</p>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
